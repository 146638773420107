/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start();
import { Uploader } from "packs/libs/uploader/Uploader";

export class UploadDirectFile{
        constructor(inputSelector) {
          this.inputSelector = inputSelector;
        }
        
        build(){
            const createFile = (input,file,countId)=> {
               const uploader = new Uploader(input,countId);
               uploader.createFile(file);
            };
            
            const inputs = document.querySelectorAll('input[type=file]');   
            inputs.forEach(function(input) {
                
                /*
            const createHiddenInputFile = (source,countId,signed_id) => {
                const input = document.createElement("input");
                const referenceNode = source.parentElement;
                input.type = "hidden";
                input.name = source.name;
                input.value = signed_id;
                input.id = source.id+"-"+countId;
                referenceNode.appendChild(input);
                return input;
            };
               
            const createPreviewFile = (source,countId, image_path) => {
                const ulContainerUploaded = this.createOrGetUlContainerPreviews();
                const referenceNode = source.parentElement;
                var iContainerDetele = document.createElement("i"); 

               
                var sourceId = source.id;
                var _this2 = this;
                var imgHtml;

                imgHtml = "<img src='"+image_path+"' />";

                iContainerDetele.onclick=event => { 
                    //    _this2.removePreview(this);
                };

                ulContainerUploaded.insertAdjacentHTML("afterbegin", `
                     <li id="${sourceId}-${countId}-preview" class="uploadFile-item-container-uploaded">
                       <div class="uploadFile-item-inner">
                           <div class="uploadFile-item-thumb preview">
                                    ${imgHtml}
                                 <div class="progress">
                                     <div class="progress-bar" role="progressbar"  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                 </div>
                           </div>
                           <div class="uploadFile-item-actions">

                           </div>
                       </div>
                     </li>
                   `);
                    const preview = referenceNode.querySelector("#"+sourceId+"-"+countId+"-preview");
                    _this2.previewSource = preview;
                    preview.querySelector(".uploadFile-item-actions").appendChild(iContainerDetele);        
                };    
                */
                

                input.classList.add("hide");
                var   counterId = 0;
                var   extraClassUlContainer = "";
                const parentInput = input.parentElement;
                const inputId = input.id;
                const inputMaxFile = (typeof input.dataset.maxFile !== 'undefined') ?  parseInt(input.dataset.maxFile) : 1;
                const inputMaxSize = (typeof input.dataset.maxSize !== 'undefined') ?  parseInt(input.dataset.maxSize) : 3;
                const inputText = (typeof input.dataset.text !== 'undefined') ?  input.dataset.text : "Select your image";
                const maxFileErrorText = (typeof input.dataset.maxFileAllowedText !== 'undefined') ?  input.dataset.maxFileAllowedText : "You can only upload a maximum of 1 files";
                const labelBtnUpload = (typeof input.dataset.browseText !== 'undefined') ?  input.dataset.browseText : "Browse File";
                const divContainerInputFileRef = document.createElement("li"); 
                var ulContainerUploaded = parentInput.querySelector(".uploadFile-container-uploaded");
                var files = (typeof input.dataset.files !== 'undefined') ?  JSON.parse(input.dataset.files) : [];
                if(typeof input.dataset.files !== 'undefined')
                    input.removeAttribute("data-files")

                divContainerInputFileRef.setAttribute("class","uploadFile-container uploadFile-item-container-uploaded");
                divContainerInputFileRef.insertAdjacentHTML("afterbegin", `
                  <div class="uploadFile-content uploadFile-item-inner">
                    <div class="uploadFile-text uploadFile-item-thumb">
                        ${inputText}
                    <div>
                    <div class="uploadFile-item-actions uploadFile-icon">
                        <i class="mdi mdi-image-search mdi-48px">
                    <div>
                    <a class="uploadFile-choose-btn btn btn-primary">
                        ${labelBtnUpload}
                        <small>Max. ${inputMaxSize}MB</small>
                    </a>
                  </div>
                `);

                divContainerInputFileRef.onclick = function () {
                    input.click();
                };
               
                if(ulContainerUploaded === null){
                    ulContainerUploaded = document.createElement("ul");
                    if(input.dataset.wrapper === "horizontal_form")
                        extraClassUlContainer = ""
                    ulContainerUploaded.setAttribute("class","uploadFile-container-uploaded "+extraClassUlContainer);
                    parentInput.appendChild(ulContainerUploaded);
                }
                ulContainerUploaded.appendChild(divContainerInputFileRef);
                input.addEventListener('change', (event) => {
                    const errorMessage = parentInput.querySelector(".invalid-feedback");
                    
                    if(errorMessage !== null)
                        errorMessage.remove();
                    
                    if(input.files.length <= inputMaxFile){
                        Array.from(input.files).forEach(file => uploadFile(input, file,divContainerInputFileRef,++counterId));
                        input.value = null;
                    }
                    else{
                         parentInput.insertAdjacentHTML("beforeend", `
                            <div class='invalid-feedback'>${maxFileErrorText}</div>
                          `);
                    }
                });
                
                Array.from(files).forEach(file => createFile(input,file,++counterId));
                
            });
            const uploadFile = (input, file, divContainerInputFileRef,countId) => {
                const url = input.dataset.directUploadUrl;
                const uploader = new Uploader(input,countId);
                uploader.build(file,url);
            };
            

            
        }
        
        
        
        
        
    }  