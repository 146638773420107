import intlTelInput from 'intl-tel-input';
import { UploadDirectFile } from "packs/libs/uploader/upload_direct_file";



(function( $ ){
    "use strict"
   $.fn.makePopupForm = function(form,name) {
        let new_window = null;
        var w = 580;
        var h = 500;
        var left = (screen.width/2) - (w/2);
        var top = (screen.height/2) - (h/2);
        
        if (new_window===null || new_window.closed)
        {
            new_window = window.open('', "formpopup", "scrollbars=yes, width=" + w + ", height="+ h + " , top=" + top + ", left=" + left);
            form.target = 'formpopup';
            form.submit();
        }        
   }; 
})( jQuery );

(function( $ ){
    "use strict"
   $.fn.makePopup = function(href,name) {
        let new_window = null;
        var w = 580;
        var h = 500;
        var left = (screen.width/2) - (w/2);
        var top = (screen.height/2) - (h/2);
        
        if (new_window===null || new_window.closed)
        {
            new_window = window.open(href, "Facebook - "+name, "scrollbars=yes, width=" + w + ", height="+ h + " , top=" + top + ", left=" + left);
        }        
   }; 
})( jQuery );

(function( $ ){
    "use strict";
   $.fn.dataTogglePopup = function() {
    $('a[data-toggle="popup"]').click(function(e)
    {
        e.preventDefault();
        let href = $(this).attr("href");
        let name = "TopLashPerformance";
        jQuery().makePopup(href,name);
       
    });
    $('form[data-toggle="popup"]').submit(function(e)
    {
        //alert(1)
        e.preventDefault();
        let form = $(this).get(0);
        
        let name = "TopLashPerformance";
        jQuery().makePopupForm(form,name);
       
    });

    return this;
   }; 
})( jQuery );

(function( $ ){
    "use strict";
   $.fn.verifyUniqSession = function() {
       $.ajax({
        url: "/unique_session"
      })
   }; 
})( jQuery );


$(function() {
    "use strict";
     var url = window.location + "";
        var path = url.replace(window.location.protocol + "//" + window.location.host + "/", "");
        $('ul#headerbarnav a').removeClass("active");
        
        var element = $('ul#headerbarnav a').filter(function() {
            var href_path = this.href.replace(window.location.protocol + "//" + window.location.host + "/", "");
            return this.href === url || this.href === path || ( url.includes(href_path) && href_path !== "" )//|| url.href.indexOf(this.href) === 0;
        });
        
        element.addClass("active"); 
});

$(function() {
    "use strict";
    $("#kt_quick_user_toggle").click(function() {
        $("#kt_quick_user").css("right",0)
    });
    $("#kt_quick_user_close").click(function() {
        $("#kt_quick_user").css("right","-395px")
    });
});




$(function() {
    "use strict";
    $("#kt_header_mobile_topbar_toggle").click(function() {
        $("#kt_body").toggleClass("topbar-mobile-on");
    });

});


$(function() {
    "use strict";
    $("#kt_header_mobile_toggle").click(function() {
        $("#kt_header_navs").toggleClass("header-navs-on");
    });

});


$(function() {
    "use strict";
    if($("#topbar_notifications_events").length > 0)
     $.ajax({
        url: "/notifications",
        dataType: 'script'
      });
});


(function( $ ){
   $.fn.modalRemote = function() {
        $(this).on('click', function(e) {
            
           const target = $(e.currentTarget).data('target');
           const remote_content = $(e.currentTarget).attr('href');
           var modal = $(target);
           const modalBody = $(target + ' .modal-content');
           modalBody.load(remote_content);
           modal.modal();
           modal.data("event",$(e.currentTarget).data('event'));
          
           modal.on('shown.bs.modal', function () {
              
               if(typeof($(e.currentTarget).data('event')) !== "undefined"){
                   if($(e.currentTarget).data('event') === "intlTelInput"){
                        
                        var input = document.querySelector("#user_profile_attributes_fullphone");
                        intlTelInput(input, {
                            initialCountry: document.querySelector("#user_profile_attributes_fullphone").dataset.countryCode,
                            preferredCountries: ['mx', 've'],
                            separateDialCode: true, 
                            hiddenInput: "phone",
                            utilsScript: document.querySelector("#user_profile_attributes_fullphone").dataset.utilsUrl

                        });
                        
                        
                        
                        
                    }
                }
            }).on('hidden.bs.modal', function () {
                    
            });
           
           
           
           
          return false;
        });
    return this;
   };
})( jQuery );

(function( $ ){
        $.fn.modalBehaivor = function() 
        {        
            $(this).on('show.bs.modal', function ()
            {
        
            });

            $(this).on('shown.bs.modal', function (e)
            {    
                if($(this).data("event") === "uploader"){
                    const upload = new UploadDirectFile('input[type=file]');
                    upload.build();
                }
    
            });

            $(this).on('hide.bs.modal', function ()
            {  
                if($(this).hasClass("remote"))
                                $(this).find(".modal-dialog > .modal-content").html("");
            });

            $(this).on('hiden.bs.modal', function ()
            {

            });



            return this;
           };
    })( jQuery );