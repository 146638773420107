import { DirectUpload } from "@rails/activestorage"

export  class Uploader {
    
  constructor(source, countId) {
    this.file = null;
    this.url = null;
    this.source = source;
    this.countId = countId;
    this.inputMaxFile = (typeof this.source.dataset.maxFile !== 'undefined') ?  parseInt(this.source.dataset.maxFile) : 1;
    this.inputMaxSize = (typeof this.source.dataset.maxSize !== 'undefined') ?  parseInt(this.source.dataset.maxSize) : 3;
    this.maxSizeErrorText = (typeof this.source.dataset.maxSizeAllowedText !== 'undefined') ?  this.source.dataset.maxSizeAllowedText : "Max size allowed 3mb.";
    this.files = (typeof this.source.dataset.files !== 'undefined') ?  this.source.dataset.files : [];
    this.previewSource = null;
  }
  
  build(file, url){
      this.file = file;
      this.url = url;
      this.upload = new DirectUpload(this.file, this.url, this);
      this.createPreview();
          
     // this.createFiles();
  }

    createUpload() {
      this.hideUploadContainer(true);
      if((this.file.size/1000 > this.inputMaxSize*1000)){
        this.maxSizeExceeded();     
      }
      else{
            this.upload.create((error, blob) => {
              if (error) {
                // Handle the error
              } else {
              
                this.incressCounterFileUploader();
                this.createHiddenInput(blob);
                this.hideUploadContainer();
              }
            });
        }
    }
    

    //Private methods
    
    createHiddenInput(blob) {
        const input = document.createElement("input");
        const referenceNode = this.source.parentElement;
        input.type = "hidden";
        input.name = this.source.name;
        input.value = blob.signed_id;
        input.id = this.source.id+"-"+this.countId;
        referenceNode.appendChild(input);
        return input;
    }
    
    maxSizeExceeded(){
        this.previewSource.classList.add("completed");
        const actionsContainer = this.previewSource.querySelector(".uploadFile-item-actions");
        var invalidFeedback = actionsContainer.querySelector(".invalid-feedback");
        if(invalidFeedback === null){
            invalidFeedback = document.createElement("span");
            invalidFeedback.setAttribute("class","invalid-feedback");
            actionsContainer.prepend(invalidFeedback);
        }
        invalidFeedback.innerHTML = this.maxSizeErrorText;
        
    }
    
    removePreview(evt){
        const referenceNode = this.source.parentElement;
        const inputHiddenRemove = referenceNode.querySelector("#"+this.source.id+"-"+this.countId);
        const previewRemove = referenceNode.querySelector("#"+this.source.id+"-"+this.countId+"-preview");
        
        this.previewSource.remove();
        
        if(inputHiddenRemove !== null){
            inputHiddenRemove.remove();
            this.decressCounterFileUploader();  
        }
        else{
            this.showUploadContainer();
        }
        
    }
    
    createPreview() {
        const ulContainerUploaded = this.createOrGetUlContainerPreviews();
        const reader  = new FileReader();
        const referenceNode = this.source.parentElement;
        var iContainerDetele = document.createElement("i"); 
        var countId = this.countId;
        var sourceId = this.source.id;
        var _this2 = this;
        var imgHtml;
        
        iContainerDetele.setAttribute("class","mdi mdi-delete delete");
        
        if(typeof(this.source.dataset.fileUploaded) === 'undefined') 
            this.source.dataset.fileUploaded = 0;
        
        reader.onloadend = function () {
            switch(_this2.file.type) {
                case "application/pdf":
                  imgHtml = "<i class='mdi mdi-pdf-box mdi-96px'></i>";
                  break;
                case "video/quicktime":
                case "video/mp4":
                case "video/mpeg":
                case "video/ogg":
                case "video/webm":
                case "video/3gpp":
                case "video/x-msvideo":
                case "application/octet-stream":
                  imgHtml = "<i class='mdi mdi-video mdi-96px'></i>";
                  break;
                case "image/jpeg":
                case "image/png":
                    imgHtml = "<img src='"+reader.result+"' />";
                    break;
                default:
                  imgHtml = "<i class='mdi mdi-cloud-question mdi-96px'></i>";
              } 

 
           
          iContainerDetele.onclick=event => { 
                _this2.removePreview(this);
            };

           ulContainerUploaded.insertAdjacentHTML("afterbegin", `
             <li id="${sourceId}-${countId}-preview" class="uploadFile-item-container-uploaded">
               <div class="uploadFile-item-inner">
                   <div class="uploadFile-item-thumb preview">
                            ${imgHtml}
                         <div class="progress">
                             <div class="progress-bar" role="progressbar"  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                         </div>
                   </div>
                   <div class="uploadFile-item-actions">
                    
                   </div>
               </div>
             </li>
           `);
            const preview = referenceNode.querySelector("#"+sourceId+"-"+countId+"-preview");
            _this2.previewSource = preview;
            preview.querySelector(".uploadFile-item-actions").appendChild(iContainerDetele);
            _this2.createUpload();
        };
      
        reader.readAsDataURL(this.file);
    }    
    

    createOrGetUlContainerPreviews(){
        const referenceNode = this.source.parentElement;
        const ulContainerUploaded = referenceNode.getElementsByClassName("uploadFile-container-uploaded");
        if(ulContainerUploaded.length === 0){
            ulContainerUploaded = document.createElement("ul"); 
            ulContainerUploaded.setAttribute("class","uploadFile-container-uploaded");
            referenceNode.appendChild(ulContainerUploaded);
            return ulContainerUploaded;
        }
        else{
            return ulContainerUploaded[0];
        }
    }
    
    incressCounterFileUploader(){
      this.source.dataset.fileUploaded = (typeof this.source.dataset.fileUploaded !== 'undefined') ?  (parseInt(this.source.dataset.fileUploaded) + 1) : 1;    
    }
    
    decressCounterFileUploader(){
      this.source.dataset.fileUploaded = (typeof this.source.dataset.fileUploaded !== 'undefined') ?  (parseInt(this.source.dataset.fileUploaded) - 1) : 0;    
      this.showUploadContainer();
    }
    
    hideUploadContainer(force=false){
        const referenceNode = this.source.parentElement;
        const ulContainerUploaded = referenceNode.querySelector(".uploadFile-container-uploaded");
        if(this.inputMaxFile <= parseInt(this.source.dataset.fileUploaded) || force)
            ulContainerUploaded.querySelector(".uploadFile-container").classList.add("hide")
        else
            ulContainerUploaded.querySelector(".uploadFile-container").classList.remove("hide")
    }
    
    showUploadContainer(){
        const referenceNode = this.source.parentElement;
        const ulContainerUploaded = referenceNode.querySelector(".uploadFile-container-uploaded");
        if(this.inputMaxFile > parseInt(this.source.dataset.fileUploaded))
            ulContainerUploaded.querySelector(".uploadFile-container").classList.remove("hide");
    }
    
    createFile(file){
        const referenceNode = this.source.parentElement;
        const ulContainerUploaded = referenceNode.querySelector(".uploadFile-container-uploaded");
        const image_path = file[0];
        const signed_id = file[1];

        this.createHiddenInputFile(signed_id);
        this.createPreviewFile(image_path);
        this.incressCounterFileUploader();
        this.hideUploadContainer();
    }
    
    createHiddenInputFile(signed_id) {
        const input = document.createElement("input");
        const referenceNode = this.source.parentElement;
        input.type = "hidden";
        input.name = this.source.name;
        input.value = signed_id;
        input.id = this.source.id+"-"+this.countId;
        referenceNode.appendChild(input);
        return input;
    }
    
    createPreviewFile(image_path) {
        const ulContainerUploaded = this.createOrGetUlContainerPreviews();
        const referenceNode = this.source.parentElement;
        var iContainerDetele = document.createElement("i"); 
        
        var countId = this.countId;
        var sourceId = this.source.id;
        var _this2 = this;
        var imgHtml;
        
        iContainerDetele.setAttribute("class","mdi mdi-delete delete");
        
        imgHtml = "<img src='"+image_path+"' />";
        
        iContainerDetele.onclick=event => { 
                _this2.removePreview(this);
            };
        
        ulContainerUploaded.insertAdjacentHTML("afterbegin", `
             <li id="${sourceId}-${countId}-preview" class="uploadFile-item-container-uploaded completed">
               <div class="uploadFile-item-inner">
                   <div class="uploadFile-item-thumb preview">
                            ${imgHtml}
                         <div class="progress">
                             <div class="progress-bar" role="progressbar"  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                         </div>
                   </div>
                   <div class="uploadFile-item-actions">
                    
                   </div>
               </div>
             </li>
           `);
            const preview = referenceNode.querySelector("#"+sourceId+"-"+countId+"-preview");
            _this2.previewSource = preview;
            preview.querySelector(".uploadFile-item-actions").appendChild(iContainerDetele);        
    }

    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress",
            event => this.directUploadDidProgress(event))
    }

    directUploadDidProgress(event) {
      const referenceNode = this.source.parentElement;
      const countId = this.countId;
      const sourceId = this.source.id;
      const preview = referenceNode.querySelector("#"+sourceId+"-"+countId+"-preview");
      
      
      let percent = ((event.loaded / event.total) * 100).toFixed(1);  
      //preview.querySelector(".progress-bar").style.width = percent+"%";//
      this.previewSource.querySelector(".progress-bar").style.width = percent+"%";
      if(percent >= 100)
        preview.classList.add("completed");
        
    }
}
